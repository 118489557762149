import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player/vimeo'
import ReactPlayerYou from 'react-player/youtube'
import shecommercePoster from '../media/poster/shecom.webp';
import Asaya from '../media/poster/asayaHome.webp';
import Comet from '../media/poster/comet2.jpg';
import NagwaPoster from '../media/poster/nagwa2.webp';
import ReveriePoster from '../media/poster/Reverie.webp';
import Oben from '../media/poster/Oben.webp'; 
import suavePoster from '../media/poster/suaveMundan2.webp';
import Model from '../components/Model';

// const VideoRender = React.lazy(()=> import('../components/VideoRender'));


const Home = ({onSectionRef}) => {
  const homeSection = useRef(null);
  const [videoLinks] = useState([
    {
      // videoData: {
      //   mp4: homeMedia.bgVideoAsaya,
      //   webm: homeMedia.bgVideoAsayaWebm,
      // },
      title: 'Comet',
      redirectLink: 'https://youtu.be/TujYvEV6oFo',
      fileName: 'Asaya.mp4',
      poster: Comet
    },
    {
      // videoData: {
      //   mp4: homeMedia.bgVideoAsaya,
      //   webm: homeMedia.bgVideoAsayaWebm,
      // },
      title: 'Asaya - Welcome Home',
      redirectLink: 'https://youtu.be/MyR755UZa60',
      fileName: 'Asaya.mp4',
      poster: Asaya
    },
    {
      // videoData: {
      //   mp4: homeMedia.bgVideoAsaya,
      //   webm: homeMedia.bgVideoAsayaWebm,
      // },
      title: 'Oben',
      redirectLink: 'https://www.youtube.com/watch?v=SLxLurULmjs',
      fileName: 'Oben.mp4',
      poster: Oben
    },
    {
      // videoData: {
      //   mp4: homeMedia.bgVideoReverie,
      //   webm: homeMedia.bgVideoReverieWebm
      // },
      title: 'REVERIE',
      redirectLink: 'https://youtu.be/owJYKAYxJSg',
      fileName: 'Reverie.mp4',
      poster: ReveriePoster
    },
    {
      // videoData: {
      //   mp4: homeMedia.bgVideoShecommerz,
      //   webm: homeMedia.bgVideoShecommerzWebm,
      // },
      title: 'Shecommerz',
      redirectLink: 'https://youtu.be/E8F7JJBM8Ew',
      fileName: 'Shecommerz.mp4',
      poster: shecommercePoster
    },
    {
      // videoData: {
      //   mp4: homeMedia.bgVideoNagwa,
      //   webm: homeMedia.bgVideoNagwaWebm,
      // },
      title: 'Nagwa x Hopo',
      redirectLink: 'https://youtu.be/IIwiVc7mZ44',
      fileName: 'Nagwa.mp4',
      poster: NagwaPoster
    },
    {
      // videoData: {
      //   mp4: homeMedia.bgVideoSuaveMundane,
      //   webm: homeMedia.bgVideoSuaveMundaneWebm,
      // },
      title: 'Suave Mundane',
      redirectLink: 'https://youtu.be/OzCJ7m4waNk',
      fileName: 'suave.mp4',
      poster: suavePoster
    }
  ])

  const [bgVideoIndex, setBgVideoIndex] = useState(0);
  const playerRefs = useRef(videoLinks.map(() => React.createRef()));
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50 

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchEnd - touchStart
    const isRightSwipe = distance < -minSwipeDistance
    const isLeftSwipe = distance > -minSwipeDistance
    if (isRightSwipe) {
      setBgVideoIndex(bgVideoIndex === 6 ? 0 : bgVideoIndex+1);
    }
    if (isLeftSwipe) {
      setBgVideoIndex(bgVideoIndex === 0 ? 6 : bgVideoIndex-1);
    }
    // if (playerRefs.current[index].current) {
    //   index === 6 ? playerRefs.current[index].current.seekTo(11, 'seconds') : index === 2 ? playerRefs.current[index].current.seekTo(13, 'seconds') : playerRefs.current[index].current.seekTo(0, 'seconds');
    // }
  }

  async function setBgVideoIndexStart(index){
    
    if (playerRefs.current[index].current) {
      index === 6 ? playerRefs.current[index].current.seekTo(11, 'seconds') : index === 2 ? playerRefs.current[index].current.seekTo(13, 'seconds') : playerRefs.current[index].current.seekTo(0, 'seconds');
    }
    await sleep(200);
    setBgVideoIndex(index);
  }

  async function setBgVideoIndexArrow(direction){
    

    if (direction === 'right') {
      if (bgVideoIndex === 6) {
        setBgVideoIndex(0);
        return;
      }
      setBgVideoIndex(bgVideoIndex + 1);
      return;
    }

    if (bgVideoIndex === 0) {
      setBgVideoIndex(6);
      return;
    }
    setBgVideoIndex(bgVideoIndex - 1);
  }

  useEffect(() => {
    if (onSectionRef && typeof onSectionRef === 'function') {
      onSectionRef(homeSection.current);
    }
  }, [onSectionRef]);

  // function handleError(index) {
  //   playerRefs.current[index].current.seekTo(0, 'seconds');
  // }

  function sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const [isModalOpen, setModalOpen] = useState(false);
  const [modelVideoUrl, setModelVideoUrl] = useState(null);
  const posterRef = useRef(videoLinks.map(() => React.createRef()));

  function handleStart(duration, index) {
    if (duration == 3) {
      posterRef.current[index].current.className = 'video-bg';
    }
  }

  function setModalParam(isTrue, videoUrl, index) {
    setModalOpen(isTrue);
    if (index !== -1) {
      setModelVideoUrl(videoLinks[index].redirectLink);
      return
    }
    if (videoUrl) {
      setModelVideoUrl(videoUrl);
    }
    
  }

  return (
   <section ref={homeSection} className='section bg-cover h-auto' id='home' >
    <div className='landing'>
      <div className='h-[full] relative'
       >
        
        {
          videoLinks.map((videoLink, index) => (
              <div className={`${bgVideoIndex === index ? "" : "video-bg" } ${index === 0 ? 'w-[107%] lg:right-[4%]':''} ${'player-wrapper'}`}>
              <img className='h-full w-full absolute top-0 left-0' src={videoLink.poster} ref={posterRef && posterRef.current[index]} alt='' loading='lazy'/>
                <ReactPlayerYou
                  // light={!isReady && videoLink.poster}
                  ref={playerRefs.current[index]}
                  className='react-player'
                  playing muted loop playsinline
                  url={videoLink.redirectLink}
                  width='100%' height='100%'
                  config={{
                    youtube: {
                      playerVars: { modestbranding: 1 }
                    }
                  }}
                  // onError={() => handleError(index)}
                  onDuration={(duration) => handleStart(duration, index)}
                  >
                  </ReactPlayerYou>
                  
              </div>
          ))
        }
        {/* <VideoRender data={videoLinks} videoIndex={bgVideoIndex} playerRef={playerRefs}/> */}
        <div className='video-nav-mobile xl:hidden'>
          {
            videoLinks.map((videoLink, index) => (
              <div key={index} onClick={() => setBgVideoIndexStart(index)} className='navbar-collection-item'>
                <div className='navlink_button w-inline-block'>
                  <div className={`${bgVideoIndex === index ? "text-white" : ""} ${'bulletpoint'}`}>•</div>
                </div>
              </div>
            ))
          }
        </div>
        <div className='div-block-starting-page flex flex-row z-20'>
          {
            videoLinks.map((videoLink, index) => (
              // <a key={index} onMouseEnter={() => setBgVideoIndexStart(index)} className='link-block w-inline-block' href={videoLink.redirectLink} target='_blank' rel="noreferrer">
              //   <div className='text-starting font-normal hover:italic'>
              //     {videoLink.title}
              //   </div>
              // </a>
              
              <div key={index} onMouseEnter={() => setBgVideoIndexStart(index)} onClick={() => setModalParam(true, videoLink.redirectLink, -1)} className='cursor-pointer w-[100%] flex long-form-home-item'>
                <img src={videoLink.poster} alt={videoLink.title} className='w-[100%] rounded-xl' />
              </div>
            ))
          }
        </div>
        
        <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} onClick={() => setModalParam(true, null, bgVideoIndex)} className='absolute top-0 left-0 w-full h-full z-10 video-shadow-overlay text-[2rem]'></div>
          <div onClick={() => setBgVideoIndexArrow('right')} className='w-[50px] right-arrow w-slider-arrow-right absolute cursor-pointer' >
          <svg viewBox="0 0 24 24" height="24" width="24" role="img" aria-hidden="true">
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.758 3.054 C 7.416 3.147,7.160 3.395,7.061 3.729 C 6.985 3.987,6.985 4.053,7.063 4.323 C 7.125 4.534,7.225 4.638,10.853 8.270 L 14.579 12.000 10.871 15.710 C 7.994 18.589,7.145 19.460,7.083 19.600 C 6.984 19.819,6.975 20.182,7.062 20.391 C 7.144 20.587,7.381 20.831,7.580 20.924 C 7.818 21.034,8.175 21.025,8.422 20.901 C 8.576 20.824,9.545 19.876,12.745 16.671 C 16.526 12.885,16.876 12.524,16.935 12.343 C 17.017 12.094,17.017 11.906,16.935 11.657 C 16.876 11.476,16.528 11.117,12.768 7.353 C 9.951 4.532,8.609 3.214,8.483 3.147 C 8.252 3.024,7.992 2.990,7.758 3.054 " fill="currentColor" stroke="none" fill-rule="evenodd">
              </path>
            </svg>
          </svg>
          </div>
          <div onClick={() => setBgVideoIndexArrow('left')} className='w-[50px] left-arrow w-slider-arrow-left absolute cursor-pointer' >
          <svg viewBox="0 0 24 24" height="24" width="24" role="img" aria-hidden="true">
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.578 3.099 C 15.424 3.176,14.455 4.124,11.255 7.329 C 7.474 11.115,7.124 11.476,7.065 11.657 C 6.983 11.906,6.983 12.094,7.065 12.343 C 7.124 12.524,7.474 12.885,11.255 16.671 C 14.023 19.443,15.433 20.827,15.540 20.879 C 15.790 21.000,15.999 21.018,16.265 20.940 C 16.581 20.849,16.844 20.590,16.936 20.283 C 17.016 20.013,17.016 19.949,16.937 19.677 C 16.875 19.466,16.775 19.362,13.147 15.730 L 9.421 12.000 13.129 8.290 C 16.006 5.411,16.855 4.540,16.917 4.400 C 17.022 4.168,17.025 3.799,16.925 3.578 C 16.834 3.377,16.518 3.094,16.325 3.041 C 16.070 2.970,15.793 2.992,15.578 3.099 " fill="currentColor" stroke="none" fill-rule="evenodd">
              </path>
            </svg>
          </svg>
        </div>
        </div>
        
      
    </div>
    <Model
        url={modelVideoUrl} // Replace with a valid video URL
        show={isModalOpen}
        onHide={() => setModalOpen(false)}
      />
      
    </section>
  );
};

export default Home;
