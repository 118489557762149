import React, { useRef, useState , useMemo, useEffect } from 'react';

import { motion } from 'framer-motion';

// Commercials posters
import shecommercePoster from '../media/poster/shecommerce.webp';
import Asaya from '../media/poster/Asaya.webp';
import Nagwa from '../media/poster/Nagwa.webp';
import Mokobara from '../media/poster/mokodora.webp';
import NishaXAsaya from '../media/poster/NishaXAsaya.webp';
import Nail_Station from '../media/poster/Nail_Station.webp';
import Mello from '../media/poster/Mello.webp';
import Atyab from '../media/poster/Atyab.webp';
import Acqua from '../media/poster/Acqua.webp';
import Keif from '../media/poster/Keif.webp';
import SpyK from '../media/poster/SpyK.webp';
import FTV from '../media/poster/FTV.webp';
import Dhahia from '../media/poster/Dhahia.webp';

import Arnav from '../media/poster/Arnav.webp';
import AsayaEverything from '../media/poster/AsayaEverything.webp';
import PhonePe from '../media/poster/PhonePe.webp';
import nothing from '../media/poster/nothing.webp';
import Insight from '../media/poster/Insight.webp';
import ArnavCofounder from '../media/poster/ArnavCofounder.webp';

import ReveriePoster from '../media/poster/Reverie.webp';
import To6 from '../media/poster/To6.webp';
import FormOfStyle from '../media/poster/FormOfStyle.webp';
import deme from '../media/poster/deme.webp';
import suave from '../media/poster/suave.webp';
import Rewind from '../media/poster/Rewind.webp';
import Maha from '../media/poster/Maha.webp';
import Phula from '../media/poster/Phula.webp';
import M8 from '../media/poster/M8.webp';
import { fadeIn } from '../variants';
// viedos
// import { paths } from '../components/MediaPaths';

const Work = () => {
    const [category] = useState([
        {
            categoryTitle: 'All',
        },
        {
            categoryTitle: 'Commercials',
        },
        {
            categoryTitle: 'Tech',
        },
        {
            categoryTitle: 'Fashion',
        }
    ])
    const [heading] = useState([
        {
          heading: 'Commercials',
          className:'commercials',
          vedioData: [
                {
                    videoLink: 'https://vimeo.com/856639345',
                    title: 'Asaya - Welcome Home',
                    poster: Asaya
                },
                {
                    videoLink: 'https://vimeo.com/857532688',
                    title: 'Nagwa x Hopo TVC Kuwait',
                    poster: Nagwa
                },
                {
                    videoLink: 'https://www.linkedin.com/posts/prabhusrikanth_bfsi-microfinance-retail-activity-7184897615925260289-q-iV?utm_source=share&utm_medium=member_android',
                    title: 'Shecommerz - Brand Film',
                    poster: shecommercePoster
                },
                {
                    videoLink: 'https://www.instagram.com/reel/CwKseqIJLME/?igsh=enUwZ2IwbWxzOWx4',
                    title: 'Mokobora',
                    poster: Mokobara
                },
                {
                    videoLink: 'https://vimeo.com/857537991',
                    title: 'FYT Foods - A home food delivery app',
                    poster: FTV
                },
                {
                    videoLink: 'https://www.instagram.com/reel/C3AkuFku4L0/?igsh=MWVpaTV5YmFpbWlrbA==',
                    title: 'Nisha Millet X Asaya',
                    poster: NishaXAsaya
                },
                {
                    videoLink: 'https://www.instagram.com/tv/CaE82LQNFmD/?igsh=azM1bGF2bzBjaTE1',
                    title: 'Dhahia Juice - UAE ad',
                    poster: Dhahia
                },
                {
                    videoLink: 'https://www.instagram.com/tv/CZcBqVshNy5/?igsh=MnRyaTJwdG9jcm9i',
                    title: 'Nail Station Brand film - Kuwait',
                    poster: Nail_Station
                },
                {
                    videoLink: '',
                    title: 'Mello - Brand Reel',
                    poster: Mello
                },
                {
                    videoLink: 'https://drive.google.com/file/d/1Fkj5PEglfTiBWyll3vIiitsX2a5uY1EJ/view?usp=sharing',
                    title: 'Atyab Al Sheikh - Product Film Kuwait',
                    poster: Atyab
                },
                {
                    // videoLink: paths.Acqua,
                    videoLink: 'https://drive.google.com/file/d/1Fkj5PEglfTiBWyll3vIiitsX2a5uY1EJ/view?usp=sharing',
                    title: 'Acqua D’ell Elba - Kuwait',
                    poster: Acqua
                },
                {
                    videoLink: 'https://www.instagram.com/reel/CfYiUgUB5LI/?igsh=MXV0Mzc5YTZ0Y216cQ==',
                    title: 'Keif - Kuwait',
                    poster: Keif
                },
                {
                    videoLink: 'https://www.instagram.com/reel/CqkpTI_AsIj/?igsh=cjVnZzN6dTJmaDhk',
                    title: 'Spyk Seltzer',
                    poster: SpyK
                },
            ]
        },
        {
            heading: 'Tech / Corporate',
            className:'tech',
            vedioData: [
                {
                    videoLink: 'https://vimeo.com/857542460',
                    title: 'Arnav Jewellery Founder Interview',
                    poster: Arnav
                },
                {
                    videoLink: 'https://vimeo.com/899443517',
                    title: 'PhonePe Event',
                    poster: PhonePe
                },
                {
                    videoLink: 'https://youtu.be/KnutZ7DQGJ4?si=g69R587yK7irhBm9',
                    title: 'Asaya - Everything Melanin',
                    poster: AsayaEverything
                },
                {
                    videoLink: 'https://www.instagram.com/reel/C5QqnyNygyQ/?igsh=ZzF2eHZwN3diM2Zw',
                    title: 'Nothing Phones Launch',
                    poster: nothing
                },
                {
                    videoLink: '',
                    title: 'Percept Insight x Udaan',
                    poster: Insight
                },
                {
                    videoLink: 'https://drive.google.com/file/d/19fkvxrVzn4XxOEM-Gnc6JBoofRTj0Rkw/view?usp=sharing',
                    title: 'Arnav Co-founder Interview.',
                    poster: ArnavCofounder
                },
            ]
        },
        {
            heading: 'Fashion',
            className:'fashion',
            vedioData: [
                {
                    videoLink: 'https://vimeo.com/730222592',
                    title: 'Reverie - Dance Fashion Film',
                    poster: ReveriePoster
                },
                {
                    videoLink: 'https://www.instagram.com/reel/CvATxjUBbiI/?igsh=OWtyaWluZnBmeHdu',
                    title: 'Tribeof6 - Dubai',
                    poster: To6
                },
                {
                    videoLink: 'https://www.instagram.com/reel/C0eD2FjhxHq/?igsh=c2xzbXVrcmRja2dn',
                    title: 'Modish - Kuwait',
                    poster: FormOfStyle
                },
                {
                    videoLink: 'https://www.instagram.com/reel/C0TV9aMhnyU/?igsh=MWwxNnJoMHl5ZTllNg==',
                    title: 'Deme',
                    poster: deme
                },
                {
                    videoLink: 'https://vimeo.com/857528166',
                    title: 'Suave Mundane - A Fashion Film',
                    poster: suave
                },
                {
                    videoLink: 'https://vimeo.com/857542620',
                    title: 'Rewind - A fashion Film',
                    poster: Rewind
                },
                {
                    videoLink: 'https://vimeo.com/857581206',
                    title: 'Maha Ghalayani - Fashion Editorial',
                    poster: Maha
                },
                {
                    videoLink: 'https://drive.google.com/file/d/1pXyy3FLqhcWtfNEVTgbNxB-RsvHbIYd9/view',
                    title: 'Phula - Fashion Editorial',
                    poster: Phula
                },
                {
                    videoLink: 'https://drive.google.com/file/d/1FA1A0WV9m_oe1I03u8h9rol_ztnHN9jN/view?usp=sharing',
                    title: 'M8 - Nails',
                    poster: M8
                },
            ]
        }
    ])

  return (
    <section className='section bg-cover h-auto' id={'work'} >
      <div className='container mx-auto'>
      <div className='flex work-section flex-col lg:flex-col h-full gap-x-24 justify-center text-center'>
        <div>
        <motion.h1
            variants={fadeIn('up', 0.3)} initial="hidden"
            whileInView={'show'} viewport={{once: true, amount: 0.5}}
            className='h1 font-neue font-extrabold'>My Work</motion.h1>
        </div>
      
            
            <div className='grid gap-[40px] font-neue'>
                <div className='flex flex-row'>
                    <a href='#/work/all' className='flex-1 w-full h-full circle'>
                        <div className='tree-rings film'>
                        </div>
                        <div>
                        ALL
                            </div>
                            
                                 
                    </a>
                    <a href='#/work/commercials' className='flex-1 w-full h-full circle'>
                    <div className='tree-rings film'>
                            
                        </div>
                        <div>
                            Commercials
                            </div>
                                    
                    </a>
                </div>
                <div className='flex flex-row'>
                    <a href='#/work/tech' className='flex-1 w-full h-full circle'>
                    <div className='tree-rings film'>
                           
                        </div>
                        <div>
                            Tech/Corporate
                            </div>
                                    
                    </a>
                    <a href='#/work/fashion' className='flex-1 w-full h-full circle'>
                    <div className='tree-rings film'>
                           
                        </div>
                        <div>
                            Fashion
                            </div>
                                    
                    </a>
                </div>
            </div>
        </div>
                    
                    
        </div>
    </section>
  );
};

export default Work;
