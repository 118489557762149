import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";

const Model = ({ url, show, onHide }) => {
  // const containerRef = useRef(null);
  // const [playerHeight, setPlayerHeight] = useState('auto');
  if (!show) return null;
  

  // const handleOnReady = (player) => {
  //   // Get video aspect ratio
  //   const { videoWidth, videoHeight } = player.getInternalPlayer();
  //   const aspectRatio = videoHeight / videoWidth;

  //   // Set height based on container width and aspect ratio
  //   if (containerRef.current) {
  //     const containerWidth = containerRef.current.offsetWidth;
  //     setPlayerHeight(containerWidth * aspectRatio + 'px');
  //   }
  // }

  return (
    <div style={styles.overlay}>
      <div style={styles.modal} >
        <ReactPlayer
            className=''
            playing loop controls
            url={url} 
            width='100%' height='100%'
            config={{
                playerOptions: {
                    playsinline: false
            }
            }}
            // onReady={handleOnReady}
            // onError={() => handleError(index)}
            >
            </ReactPlayer>
        <button style={styles.closeButton} onClick={onHide}>
          ✕
        </button>
      </div>
    </div>
  );
};

Model.propTypes = {
  url: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Background with opacity
    backdropFilter: "blur(10px)", // Background blur
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    position: "relative",
    width: "65%",
    height: "65%",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "transparent",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
  },
};

export default Model;
