import React, { useEffect, useRef } from 'react';
// import components
import Logo from '../img/header/headerLogo.png'
import MobileNavWork from './MobileNavWork';

const HeaderWork = ({isHeaderChange}) => {
  return (
  <header className={`${isHeaderChange ? "bg-black h-[100px]" : "h-[100px]"} ${'fixed w-full px-[30px] lg:px-[100px] z-30 flex items-center'}`}>
    <div className='flex flex-col lg:flex-row items-center w-full justify-center'>
      {/* nav - initially hidden - show on desktop mode */}
      <nav className='hidden lg:flex gap-x-12 font-extrabold font-neue left-[-45%] relative'>
        <a href='https://www.gautammonangifilms.com'
              activeClass='active'
              className={`${isHeaderChange ? "text-[#ffffff]" : "text-[#ffffff] hover:text-primary"} ${'cursor-pointer text-[22px]  transition'}`}>
          Home
        </a>
        {/* <a href='https://www.gautammonangifilms.com#shorts'
              activeClass='active'
              smooth={true}
              spy={true} className={`${isHeaderChange ? "text-[#ffffff]" : "text-[#ffffff] hover:text-primary"} ${'cursor-pointer text-[22px]  transition'}`}>
          Shorts
        </a>
        <a href='https://www.gautammonangifilms.com#work'
              activeClass='active'
              smooth={true}
              spy={true}
              offset={-30} className={`${isHeaderChange ? "text-[#ffffff]" : "text-[#ffffff] hover:text-primary"} ${'cursor-pointer text-[22px]  transition'}`}>
          Work
        </a>
        <a href='https://www.gautammonangifilms.com#about'
              activeClass='active'
              smooth={true}
              spy={true}
              offset={-30}  className={`${isHeaderChange ? "text-[#ffffff]" : "text-[#ffffff] hover:text-primary"} ${'cursor-pointer text-[22px]  transition'}`}>
          About
        </a>
        <a href='https://www.gautammonangifilms.com#contact'
              activeClass='active'
              smooth={true}
              spy={true}
              offset={5000}  className={`${isHeaderChange ? "text-[#ffffff]" : "text-[#ffffff] hover:text-primary"} ${'cursor-pointer text-[22px]  transition'}`}>
          Contact
        </a> */}
      </nav>
      {/* logo */}
      <a href='https://www.gautammonangifilms.com'
              activeClass='active' className={`${'max-w-[170px] cursor-pointer'}`}>
        <img src={Logo} alt='gautammonangifilms logo'/>
      </a>
    </div>
     <MobileNavWork isHeaderChange={isHeaderChange}/>
  </header>
  );
};

export default HeaderWork;
